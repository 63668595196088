import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import formStyles from './contact.module.scss'

const ContactPage = () => {
    return (
        <Layout>
            <SEO title="Contact" keywords={["Joshua Batty", "Contact", "Berlin", "Creative Coder"]}/>
            <h1>Contact Me</h1>
            <p>I'm based in Berlin, Germany. Drop me a line below if you are interested in discussing collaboration opportunities, presentations and workshops. or just to say hi!</p>

            <form action="https://formspree.io/f/xleoydyk" method="POST">
                
                <div className={formStyles.contactinfo}>
                    <label><span>Your Name *</span>
                    <input type="text" name="name"/></label>
                    
                    <label><span>Your Email *</span>
                    <input type="email" name="_replyto"/></label>
                </div>

                <label><span>Subject *</span>
                <input type="text" name="_subject"/></label>

                <label><span>Message *</span>
                <textarea name="message"> </textarea></label>

                <input type="submit" value="Send"/>
                
            </form>
        </Layout>
    )
}

export default ContactPage