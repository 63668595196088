import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import footerStyles from './footer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)

    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.links}>
                <a href="https://github.com/JoshuaBatty" aria-label="Github" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'github']} size="1x" style={{ color: "#000000" }} />
                </a>

                <a href="https://www.youtube.com/channel/UCwECa14vqKUXBPgFknInjyg" aria-label="Youtube" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'youtube']} size="1x" style={{ color: "#000000" }} />
                </a>

                <a href="https://vimeo.com/mindbuffer" aria-label="Vimeo" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'vimeo']} size="1x" style={{ color: "#000000" }} />
                </a>

                <a href="https://soundcloud.com/mindbuffer" aria-label="Soundcloud" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'soundcloud']} size="1x" style={{ color: "#000000" }} />
                </a>

                <a href="https://www.instagram.com/mindbuffer/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'instagram']} size="1x" style={{ color: "#000000" }} />
                </a>

                <a href="https://www.facebook.com/mindbuffer/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={["fab", "facebook"]} size="1x" style={{ color: "#000000" }} />
                </a>
            </div>

            <p>Created by {data.site.siteMetadata.author} © 2021</p>

        </footer>
    )
}

export default Footer
